import { useRef, useCallback, useState } from 'react';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'

// import forge from 'node-forge';
import QRCode from 'qrcode';


// import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';


import Col from 'react-bootstrap/Col';

  
import { Line } from 'react-chartjs-2';
import Chart, { CategoryScale } from 'chart.js/auto';


import Table from 'react-bootstrap/Table'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';



// import tws from '../../img/icon-512x512.png';
import tws from '../../img/logo_certificat.png';
// https://peckconsulting.s3.amazonaws.com/fontconverter/fontconverter.html
import Starjedi from '../../font/Starjedi/Starjedi-normal.js';

// // import SourceCodePro from '../../font/SourceCodePro/SourceCodePro-VariableFont_wght-normal.js';
// // import SourceCodeProBold from '../../font/SourceCodePro/SourceCodePro-Bold-normal.js';
// // import SourceCodeProBold from '../../font/SourceCodePro/SourceCodePro-Bold-normal.js';
// // import SourceCodeProRegular from '../../font/SourceCodePro/SourceCodePro-Regular-normal.js';
// import SourceCodePro from '../../font/SourceCodePro/SourceCodePro-VariableFont_wght-normal.js';

// import SourceCodeProBold from '../../font/SourceCodePro/SourceCodePro-Bold-normal.js';

import SourceCodeProBlack from '../../font/SourceCodePro/SourceCodePro-Black-normal.js';
import SourceCodeProBlackItalic from '../../font/SourceCodePro/SourceCodePro-BlackItalic-normal.js';
import SourceCodeProBold from '../../font/SourceCodePro/SourceCodePro-Bold-normal.js';
import SourceCodeProBoldItalic from '../../font/SourceCodePro/SourceCodePro-BoldItalic-normal.js';
import SourceCodeProExtraBold from '../../font/SourceCodePro/SourceCodePro-ExtraBold-normal.js';
import SourceCodeProExtraBoldItalic from '../../font/SourceCodePro/SourceCodePro-ExtraBoldItalic-normal.js';
import SourceCodeProExtraLight from '../../font/SourceCodePro/SourceCodePro-ExtraLight-normal.js';
import SourceCodeProExtraLightItalic from '../../font/SourceCodePro/SourceCodePro-ExtraLightItalic-normal.js';
import SourceCodeProItalic from '../../font/SourceCodePro/SourceCodePro-Italic-normal.js';
import SourceCodeProLight from '../../font/SourceCodePro/SourceCodePro-Light-normal.js';
import SourceCodeProLightItalic from '../../font/SourceCodePro/SourceCodePro-LightItalic-normal.js';
import SourceCodeProMedium from '../../font/SourceCodePro/SourceCodePro-Medium-normal.js';
import SourceCodeProMediumItalic from '../../font/SourceCodePro/SourceCodePro-MediumItalic-normal.js';
import SourceCodeProRegular from '../../font/SourceCodePro/SourceCodePro-Regular-normal.js';
import SourceCodeProSemiBold from '../../font/SourceCodePro/SourceCodePro-SemiBold-normal.js';
import SourceCodeProSemiBoldItalic from '../../font/SourceCodePro/SourceCodePro-SemiBoldItalic-normal.js';

import MaterialIconsRegular from '../../font/MaterialIcons/MaterialIcons-Regular-normal.js';



import { BsFiletypePdf, BsFiletypeCsv, BsFileEarmarkExcel } from "react-icons/bs";
import { MdOutlineSecurity } from "react-icons/md";





import { Line } from 'react-chartjs-2';










// Données pour le graphique
const data_bar = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
  datasets: [
    {
      label: 'Sales',
      data: [50, 60, 70, 80, 90, 100],
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    },
  ],
};

// Options du graphique
const options_bar = {
  scales: {
    // yAxes: [{ ticks: { beginAtZero: true } }],
  },
};



const overlimit = (WBGT_received_at, WBGT_data, HighLim, LowLim,   received_atTempHum, Temp_data, ColdLim, DateTimeRange) => {



  const overlim = (received_at, data, Lim, DateTimeRange) => {
    let n = 1;

    let over = data[0]>=Lim;

    let overLim = [];

    let startOver = null;
    let endOver = null;
    // let endOver = dateformat(DateTimeRange[1]);

    if (data[0]>=Lim){
      startOver = received_at[0];
      // endOver = dateformat(DateTimeRange[1]);
    }

    for (let i = 0; i < data.length; i++) {

      if ( (data[i] >= Lim) ==!over) {
        if (data[i] >= Lim) {
          startOver = received_at[i];
        }
        else{
          endOver=received_at[i-1];
          overLim.push([n, startOver, endOver]);
          startOver = null;
          // endOver = null;
          n++;
        }
        // over = !over;
        over = data[i] >= Lim;
      } 

    }

    if(startOver){
      overLim.push([n, startOver, endOver ]);
    }
    

    return overLim;
  }




  const overHighLim = overlim(WBGT_received_at, WBGT_data, HighLim, DateTimeRange);
  const overLowLim = overlim(WBGT_received_at, WBGT_data, LowLim, DateTimeRange);
  const overColdLim = overlim(received_atTempHum, Temp_data.map(x => -x), -ColdLim, DateTimeRange);


  // let overA = WBGT_data[0]>HighLim;

  // // let overHighLim = [[1, WBGT_received_at[0], WBGT_received_at[3]],[2, WBGT_received_at[5], WBGT_received_at[7]]];
  // let overHighLim = [[]];

  // let startOver = null;
  // let endOver = null;

  // if (WBGT_data[0]>HighLim){
  //   startOver = WBGT_received_at[0];
  //   endOver = WBGT_received_at[WBGT_received_at.length];
  // }

  // for (let i = 0; i < WBGT_data.length; i++) {

  //   if ( (WBGT_data[i] > HighLim) ==!overA) {
  //     if (WBGT_data[i] > HighLim) {
  //       startOver=WBGT_received_at[i];
  //     }
  //     else{
  //       endOver=WBGT_received_at[i-1];
  //       overHighLim.push([startOver, endOver]);
  //       startOver = null;
  //       endOver = null;
  //     }
  //     // overA = !overA;
  //     overA = WBGT_data[i] > HighLim;
  //   } 

  // }

  // if(startOver||startOver){
  //   overHighLim.push([startOver, endOver]);
  // }

  return { overHighLim, overLowLim, overColdLim };
};




const dateformat = (datetime) => {

  // Obtenez les composants de la date et de l'heure
  const year = datetime.getFullYear();
  const month = String(datetime.getMonth() + 1).padStart(2, '0');
  const day = String(datetime.getDate()).padStart(2, '0');
  const hours = String(datetime.getHours()).padStart(2, '0');
  const minutes = String(datetime.getMinutes()).padStart(2, '0');
  const seconds = String(datetime.getSeconds()).padStart(2, '0');

  // Obtenez le fuseau horaire de l'utilisateur
  const timeZoneOffset = datetime.getTimezoneOffset() / 60;
  const timeZoneSign = timeZoneOffset > 0 ? '-' : '+';
  const timeZoneValue = Math.abs(timeZoneOffset);
  const timeZoneString = `UTC${timeZoneSign}${String(timeZoneValue).padStart(2, '0')}`;

  // Assemblez la chaîne de caractères dans le format souhaité
  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${timeZoneString}`;

  return formattedDateTime;
};


const uniqueFilename = (nom) => {
  const timestamp = new Date().toISOString().replace(/[-:]/g, '');
  // const randomId = Math.random().toString(36).substring(2, 8);
  // const randomId = Math.random().toString(36).substring(2, 8).toUpperCase();
  const randomId = Math.random().toString(36).substring(2, 8).toUpperCase()+Math.random().toString(36).substring(2, 8).toUpperCase()+Math.random().toString(36).substring(2, 8).toUpperCase()+Math.random().toString(36).substring(2, 8).toUpperCase();
  const uniqueName = `${nom}_${timestamp}_${randomId}.pdf`
  const saveName = `${nom}_${timestamp}`  
  return { uniqueName, randomId, saveName };
};

// Fonction pour générer un QR code avec le lien vers le PDF
const generateQRCode = async (pdfURL) => {
  try {
      const qrCodeDataURL = await QRCode.toDataURL(pdfURL);
      return qrCodeDataURL;
  } catch (error) {
      console.error('Erreur lors de la génération du QR code :', error);
      throw error;
  }
};

// Fonction pour générer le PDF avec le QR code
const generatePDF = async (temp, wbgt, qrCodeDataURL, ChartURL_WBGT, ChartURL_Temp, DateTimeRange, spotName, spotCoordinates, randomId, Name, overHighLim, overLowLim, overColdLim, M, M_key, M_types, CAV, CAV_key, CAV_types) => {
  try {
      const doc = new jsPDF(
        {
          orientation: 'p',
          unit: 'mm',
          format: 'a4',
          font: 'helvetica', // Spécifie la police Helvetica  helvetica 'SourceCodePro', 'ExtraLightItalic'
          putOnlyUsedFonts:true
         }
      );


      
      // doc.setFont('Starjedi', 'normal');


      // doc.setFont('SourceCodePro', 'Black');
      // doc.setFont('SourceCodePro', 'BlackItalic');
      // doc.setFont('SourceCodePro', 'Bold');
      // doc.setFont('SourceCodePro', 'BoldItalic');
      // doc.setFont('SourceCodePro', 'ExtraBold');
      // doc.setFont('SourceCodePro', 'ExtraBoldItalic');
      // doc.setFont('SourceCodePro', 'ExtraLight');
      // doc.setFont('SourceCodePro', 'ExtraLightItalic');
      // doc.setFont('SourceCodePro', 'Italic');
      // doc.setFont('SourceCodePro', 'Light');
      // doc.setFont('SourceCodePro', 'LightItalic');
      // doc.setFont('SourceCodePro', 'Medium');
      // doc.setFont('SourceCodePro', 'MediumItalic');
      // doc.setFont('SourceCodePro', 'Regular');
      // doc.setFont('SourceCodePro', 'SemiBold');
      // doc.setFont('SourceCodePro', 'SemiBoldItalic');

      // doc.setFont('SourceCodePro', 'Regular'); //, 'Black' 'BlackItalic' 'Bold' 'BoldItalic' 'ExtraBold' 'ExtraBoldItalic' 'ExtraLight' 'ExtraLightItalic' 'Italic' 'LightItalic' 'Medium' 'MediumItalic' 'Regular' 'Regular' 'SemiBold' 'SemiBoldItalic'
      
      
      console.log(doc.getFontList())

      // Obtenir le fuseau horaire de l'utilisateur
      const userdate =  dateformat(new Date());
      
      // Génération du nom de fichier unique
      const start_time = DateTimeRange[0].toISOString().replace(/[-:]/g, '');
      const end_time = DateTimeRange[1].toISOString().replace(/[-:]/g, '');
      const nomFichier = `${Name}_${spotName}_${start_time}-${end_time}.pdf`;


      const start = dateformat(new Date(DateTimeRange[0]));
      const end = dateformat(new Date(DateTimeRange[1]));

      // console.log(doc.getFont())
      

      doc.setPage(1);
      // Ajouter du texte au document avec différentes tailles de texte
      doc.setFontSize(20); // Définir la taille du texte à 12 points
      doc.setFont('SourceCodePro', 'Medium');
      // doc.setFont('helvetica', 'normal');


      // doc.setTextColor('#C00000')
      if(temp&&wbgt){
        doc.text('Certificat climatique', 11, 35);
      }
      else if(wbgt){
        doc.text('Certificat :', 11, 30);
        // doc.setFontSize(15); // Définir la taille du texte à 12 points
        doc.text('Fortes chaleures', 11, 40);
      }
      else if(temp){
        doc.text('Certificat :', 11, 30);
        // doc.setFontSize(15); // Définir la taille du texte à 12 points
        doc.text('Grand froid', 11, 40);
      }



      // doc.setFont('helvetica', 'normal');
      // doc.setFont('SourceCodePro', 'ExtraLightItalic');

      // doc.text('selon ISO 7243', 100, 19);
      // doc.text('Mesures éffectuées avec des capteurs construits sur la base de la norme ISO 7243, et controlé par un laboratoire accrédité ISO 1725', 10, 29, 70, 20);
      // doc.text('Sur demande, nous pouvons vous présenter notre protocole de mesure et nos procedures de contrôle qualité', 10, 39);

      autoTable(doc, {
        margin: { top: 45, left:10, right: 10 }, // Spécifie le décalage par rapport au bord supérieur et gauche de la page
        theme: 'plain',

        bodyStyles:{
          font: 'SourceCodePro',
          setFontSize: 11,
          fontStyle: 'Regular',
        },

        body: [
          ['Mesures éffectuées avec des capteurs de température WBGT¹ construits sur la base de la norme ISO 7243, en cours de contôle² par un laboratoire accrédité ISO 1725.'],
          // ['Mesures éffectuées avec des capteurs construits sur la base de la norme ISO 7243, et controlé par un laboratoire accrédité ISO 1725'],
          // ['Les capteurs sont construits sur la base de la norme ISO 7243 controlé par un laboratoire accrédité ISO 1725.'],
          // ['Sur demande, nous pouvons vous présenter notre protocole de mesure et nos procedures de contrôle qualité.'],
        ],
        // html: true // Activer le formatage HTML
      });


      

      // doc.addImage(tws, 'PNG', 100, 10, 100, 30); //10, 3
      doc.addImage(tws, 'PNG', 110, 10, 90, 27); //50, 15
      // doc.addImage(tws, 'PNG', 120, 10, 80, 24); //50, 15
      // doc.addImage(tws, 'PNG', 120, 10, 70, 21); //50, 15

      doc.setFontSize(12); // Définir la taille du texte à 12 points
      // doc.setFont('helvetica', 'normal');

      doc.addImage(qrCodeDataURL, 'PNG', 10, 70, 50, 50); // Ajoute le QR code

      doc.setFontSize(9); // Définir la taille du texte à 12 points
      doc.setFont('SourceCodePro', 'Medium');
      // doc.setTextColor('#000000')
      doc.text(`ID: ${randomId}`, 10, 125);


      autoTable(doc, {
        margin: { top: 5, left:75, right: 10 }, // Spécifie le décalage par rapport au bord supérieur et gauche de la page
        // lineWidth : 15,
        // startY: 50,
        theme: 'plain',

        bodyStyles:{
          font: 'SourceCodePro',
          fontStyle: 'Regular',
        },


        body: [
          ['Entreprise:', 'Beton-Solide SA'],
          ['IDE:', 'CHE-296.372.159'],
          ['Nom du chantier:', `${spotName}`],
          ['Longitude:', `${spotCoordinates[0]}`],
          ['Latitude:', `${spotCoordinates[1]}`],
          ['Date d\'émission du certificat:', `${userdate}`],
          ['Début:', `${start}`],
          ['Fin:', `${end}`],
          ...(wbgt ? [
            ['Métabolisme énergétique³:', `${M_types[M_key]}`],
            ['CAV⁴ (vêtements) [°C-WBGT]:', `${CAV_types[CAV_key]}`],
          ] : []),
        ],
        // html: true // Activer le formatage HTML
      });

      // autoTable(doc, {
      //   margin: { top: 100, left:10, right: 10 }, // Spécifie le décalage par rapport au bord supérieur et gauche de la page
      //   // startY: 50,
      //   theme: 'plain',

      //   body: [
      //     ['Estimation du métabolisme énergétique:', `${M_types[M_key]}`],
      //     ['Valeur d\'ajustement pour les vêtement (CAV):', `${CAV_types[CAV_key]} °C-WBGT`],
      //   ]
      // });

      autoTable(doc, {
        // startY: 255,
        ...(wbgt ? { startY: 255 } : { startY: 262 }),
        // margin: { top: 100, left:10, right: 10 }, // Spécifie le décalage par rapport au bord supérieur et gauche de la page
        margin: { left:10, right: 10 }, // Spécifie le décalage par rapport au bord supérieur et gauche de la page
        theme: 'plain',

        bodyStyles:{
          font: 'SourceCodePro',
          fontSize: 8,
          fontStyle: 'Regular',
        },
        body: [
          ['¹ L\'indice de température WBGT quantifie le stresse thermique sur l\'organisme, lié à la température, l\'humidité et le rayonement solaire directe et indirect.'],
          ['² Sur demande, nous pouvons vous présenter notre protocole de mesures et nos procédures de contrôle qualité.'],
          // ['³ Voir Annexes A.    ⁴ Voir Annexes B.'],
          ...(wbgt ? [
            ['³ Voir Annexes A.    ⁴ Voir Annexes B.'],
          ] : []),
          // ['Mesures éffectuées avec des capteurs construits sur la base de la norme ISO 7243, et controlé par un laboratoire accrédité ISO 1725'],
          // ['Les capteurs sont construits sur la base de la norme ISO 7243 controlé par un laboratoire accrédité ISO 1725.'],
          // ['Sur demande, nous pouvons vous présenter notre protocole de mesure et nos procedures de contrôle qualité.'],
        ],
        // html: true // Activer le formatage HTML
      });


      if(wbgt){

        doc.addImage(ChartURL_WBGT, 'PNG', 10, 155, 190, 90); // Ajoute le QR code
        // doc.addImage(ChartURL_Temp, 'PNG', 10, 210, 200, 80); // Ajoute le QR code
        // doc.addImage(ChartURL_Temp, 'PNG', 10, 120, 190, 100); // Ajoute le QR code

        doc.addPage();

        autoTable(doc, {
          // startY: 2000,
          // startY: 230,
          // startY: 290,
          theme: 'grid',

          headStyles: {
            fillColor: '#DC3545', // #DC3545 #FFA500 #28A745// Changer la couleur de fond de la ligne d'en-tête
            font: 'SourceCodePro',
            fontStyle: 'Black',
          },
          bodyStyles:{
            font: 'SourceCodePro',
            fontStyle: 'Regular',
          },

          head: [
            [
              // { content: 'Titre', colSpan: 2, styles: { halign: 'center', fillColor: [22, 160, 133] } },
              { content: 'Période(s) au-delà de la limite personnes acclimatées', colSpan: 3, styles: { halign: 'center' } },
              // '',
            ],
            // ['#','Début', 'Fin'],
            [
              { content: '#', styles: { textColor: [0, 0, 0], fillColor: [255, 255, 255], lineWidth: 0.1, fontStyle: 'Bold' } },
              { content: 'Début', styles: { textColor: [0, 0, 0], fillColor: [255, 255, 255], lineWidth: 0.1, fontStyle: 'Bold' } },
              { content: 'Fin', styles: { textColor: [0, 0, 0], fillColor: [255, 255, 255], lineWidth: 0.1, fontStyle: 'Bold' } },
            ],
          ],

          body: overHighLim,


        });

        autoTable(doc, {
          // startY: 120,
          theme: 'grid',
          headStyles: {
            fillColor: '#FFA500', // #DC3545 #FFA500 #28A745// Changer la couleur de fond de la ligne d'en-tête
            font: 'SourceCodePro',
            fontStyle: 'Black',
          },
          bodyStyles:{
            font: 'SourceCodePro',
            fontStyle: 'Regular',
          },
          head: [
            [
              // { content: 'Titre', colSpan: 2, styles: { halign: 'center', fillColor: [22, 160, 133] } },
              { content: 'Période(s) au-delà de la limite personnes non-acclimatées', colSpan: 3, styles: { halign: 'center' } },
              // '',
            ],
            // ['#','Début', 'Fin'],
            [
              { content: '#', styles: { textColor: [0, 0, 0], fillColor: [255, 255, 255], lineWidth: 0.1, fontStyle: 'Bold' } },
              { content: 'Début', styles: { textColor: [0, 0, 0], fillColor: [255, 255, 255], lineWidth: 0.1, fontStyle: 'Bold' } },
              { content: 'Fin', styles: { textColor: [0, 0, 0], fillColor: [255, 255, 255], lineWidth: 0.1, fontStyle: 'Bold' } },
            ],
          ],

          body: overLowLim,


        });



      }





      if(temp){
        if(wbgt){
        doc.addPage();
        }
        doc.addImage(ChartURL_Temp, 'PNG', 10, ...(wbgt ? [10] : [155]), 190, 90); // Ajoute le QR code


        if(!wbgt){
          doc.addPage();
          }
        autoTable(doc, {
          ...(wbgt ? { startY: 120 } : {}),
          theme: 'grid',
          headStyles: {
            fillColor: '#04D1FA', // #DC3545 #FFA500 #28A745// Changer la couleur de fond de la ligne d'en-tête
            font: 'SourceCodePro',
            fontStyle: 'Black',
          },
          bodyStyles:{
            font: 'SourceCodePro',
            fontStyle: 'Regular',
          },
          head: [
            [
              // { content: 'Titre', colSpan: 2, styles: { halign: 'center', fillColor: [22, 160, 133] } },
              { content: 'Période(s) de température inférieure à 5°C', colSpan: 3, styles: { halign: 'center' } },
              // '',
            ],
            // ['#','Début', 'Fin'],
            [
              { content: '#', styles: { textColor: [0, 0, 0], fillColor: [255, 255, 255], lineWidth: 0.1, fontStyle: 'Bold' } },
              { content: 'Début', styles: { textColor: [0, 0, 0], fillColor: [255, 255, 255], lineWidth: 0.1, fontStyle: 'Bold' } },
              { content: 'Fin', styles: { textColor: [0, 0, 0], fillColor: [255, 255, 255], lineWidth: 0.1, fontStyle: 'Bold' } },
            ],
          ],

          body: overColdLim,


        });
      }
      





      if(wbgt){


        doc.addPage();

        doc.setFontSize(20); // Définir la taille du texte à 12 points
        doc.setFont('SourceCodePro', 'Medium');
        doc.text('Annexes A', 20, 25);


        autoTable(doc, {
          // margin: { top: 20, left:75, right: 10 }, // Spécifie le décalage par rapport au bord supérieur et gauche de la page
          startY: 40,
          theme: 'grid',

          headStyles: {
            fillColor: '#D9EDF7', // #DC3545 #FFA500 #28A745// Changer la couleur de fond de la ligne d'en-tête
            textColor: '#31708F',
            font: 'SourceCodePro',
            fontStyle: 'Black',
          },
          bodyStyles:{
            font: 'SourceCodePro',
            fontStyle: 'Regular',
            // FontSize: 11,
          },
          columnStyles: {
            1: {cellWidth: 35},
          },
          head: [
            [
              // { content: 'Titre', colSpan: 2, styles: { halign: 'center', fillColor: [22, 160, 133] } },
              { content: 'Classification des niveaux de métabolisme énergétique d\'après l\'ISO 8996', colSpan: 3, styles: { halign: 'center' }}
              // '',
            ],
          ],

          body: [
            [
            { content: '#', styles: { fontStyle: 'Bold' }},
            { content: 'Métabolisme énergétique [W]', styles: { fontStyle: 'Bold' }},
            { content: 'Exemple', styles: { fontStyle: 'Bold' }},
            'Métabolisme énergétique [W]',
            ],
            [
              { content: '#0', styles: { fillColor: M_key === 0 ? '#DEE2E6' : '#FFFFFF', textColor: M_key === 0 ? '#000000' : '#505050', fontStyle: M_key === 0 ? 'Bold' : 'Regular' } },
              { content: 'Repos: 115W  (100 à 125)', styles: { fillColor: M_key === 0 ? '#DEE2E6' : '#FFFFFF', textColor: M_key === 0 ? '#000000' : '#505050' } },
              { content: 'Repos, position assise confortable', styles: { fillColor: M_key === 0 ? '#DEE2E6' : '#FFFFFF', textColor: M_key === 0 ? '#000000' : '#505050' } },
            ],
            [
              { content: '#1', styles: { fillColor: M_key === 1 ? '#DEE2E6' : '#FFFFFF', textColor: M_key === 1 ? '#000000' : '#505050', fontStyle: M_key === 1 ? 'Bold' : 'Regular' } },
              { content: 'Faible: 180W (125W à 235W)', styles: { fillColor: M_key === 1 ? '#DEE2E6' : '#FFFFFF', textColor: M_key === 1 ? '#000000' : '#505050' } },
              { content: 'Travail manuel léger (écriture, frappe à la machine, dessin, couture, comptabilité); travail des mains et des bras (petits outils d\'établi, inspection, assemblage ou triage de matériaux légers); travail des bras et des jambes (conduite de véhicule dans des conditions normales, manœuvre d\'un interrupteur à pied ou d\'une pédale). Debout: perceuse (petites pièces); fraiseuse (petites pieces); bobinage; enroulement de petites armatures; usinage avec outlis de faible puissance; marche occasionnelle sur une surface plane (vitesse jusqu\'à 2,5 km/h).', styles: { fillColor: M_key === 1 ? '#DEE2E6' : '#FFFFFF', textColor: M_key === 1 ? '#000000' : '#505050' } },
            ],
            [
              { content: '#2', styles: { fillColor: M_key === 2 ? '#DEE2E6' : '#FFFFFF', textColor: M_key === 2 ? '#000000' : '#505050', fontStyle: M_key === 2 ? 'Bold' : 'Regular' } },
              { content: 'Modéré: 300W (235W à 360W)', styles: { fillColor: M_key === 2 ? '#DEE2E6' : '#FFFFFF', textColor: M_key === 2 ? '#000000' : '#505050' } },
              { content: 'Travail soutenu des mains et des bras (cloutage, limage); travail des bras et des jambes (manœuvre sur chantiers de camions, tracteurs ou engins); travall des bras et du tronc (travail au marteau pneumatique, accouplement de véhicules, plâtrage, manipulation intermittente de matériaux modérément lourds, sarclage, binage, cueillette de fruits ou de légumes; poussée ou traction de charrettes légères ou de brouettes; marche à une vitesse de 2,5 à 5,5 km/h sur une surface plane; forgeage).', styles: { fillColor: M_key === 2 ? '#DEE2E6' : '#FFFFFF', textColor: M_key === 2 ? '#000000' : '#505050' } },
            ],
            [
              { content: '#3', styles: { fillColor: M_key === 3 ? '#DEE2E6' : '#FFFFFF', textColor: M_key === 3 ? '#000000' : '#505050', fontStyle: M_key === 3 ? 'Bold' : 'Regular' } },
              { content: 'Élevé: 415W (360W à 465W)', styles: { fillColor: M_key === 3 ? '#DEE2E6' : '#FFFFFF', textColor: M_key === 3 ? '#000000' : '#505050' } },
              { content: 'Travail intense des bras et du tronc; transport de matériaux lourds; pelletage; travail au marteau; sciage, planage ou ciselage de bois dur: fauchage manuel; excavation; marche à une vitesse de 5,5 à 7 km/h sur une surface plane. Poussée ou traction de charrettes à bras ou de brouettes lourdement chargées; enlèvement de copeaux de pièces moulées; pose de blocs de béton.', styles: { fillColor: M_key === 3 ? '#DEE2E6' : '#FFFFFF', textColor: M_key === 3 ? '#000000' : '#505050' } },
            ],
            [
              { content: '#4', styles: { fillColor: M_key === 4 ? '#DEE2E6' : '#FFFFFF', textColor: M_key === 4 ? '#000000' : '#505050', fontStyle: M_key === 4 ? 'Bold' : 'Regular' } },
              { content: 'Très élevé: 520W (>465W)', styles: { fillColor: M_key === 4 ? '#DEE2E6' : '#FFFFFF', textColor: M_key === 4 ? '#000000' : '#505050' } },
              { content: 'Activité très intense à allure rapide proche du maximum; travail à la hache; pelletage ou excavation à un rythme intensif; action de monter des escaliers, une rampe ou une échelle; marche rapide à petits pas; course sur une surface plane; marche à une vitesse supérieure à 7 km/h sur une surface plane.', styles: { fillColor: M_key === 4 ? '#DEE2E6' : '#FFFFFF', textColor: M_key === 4 ? '#000000' : '#505050' } },
            ],
            
            // ['#0','Repos: 115W  (100 à 125)',
            // 'Repos, position assise confortable'],
            // ['#1','Faible: 180W (125W à 235W) ',
            // 'Travail manuel léger (écriture, frappe à la machine, dessin, couture, comptabilité); travail des mains et des bras (petits outils d\'établi, inspection, assemblage ou triage de matériaux légers); travail des bras et des jambes (conduite de véhicule dans des conditions normales, manœuvre d\'un interrupteur à pied ou d\'une pédale). Debout: perceuse (petites pièces); fraiseuse (petites pieces); bobinage; enroulement de petites armatures; usinage avec outlis de faible puissance; marche occasionnelle sur une surface plane (vitesse jusqu\'à 2,5 km/h).'],
            // ['#2','Modéré: 300W (235W à 360W)',
            // 'Travail soutenu des mains et des bras (cloutage, limage); travail des bras et des jambes (manœuvre sur chantiers de camions, tracteurs ou engins); travall des bras et du tronc (travail au marteau pneumatique, accouplement de véhicules, plâtrage, manipulation intermittente de matériaux modérément lourds, sarclage, binage, cueillette de fruits ou de légumes; poussée ou traction de charrettes légères ou de brouettes; marche à une vitesse de 2,5 à 5,5 km/h sur une surface plane; forgeage).'],
            // ['#3','Élevé: 415W (360W à 465W)',
            // 'Travail intense des bras et du tronc; transport de matériaux lourds; pelletage; travail au marteau; sciage, planage ou ciselage de bois dur: fauchage manuel; excavation; marche à une vitesse de 5,5 à 7 km/h sur une surface plane. Poussée ou traction de charrettes à bras ou de brouettes lourdement chargées; enlèvement de copeaux de pièces moulées; pose de blocs de béton.'],
            // ['#4','Très élevé: 520W (>465W)',
            // 'Activité très intense à allure rapide proche du maximum; travail à la hache; pelletage ou excavation à un rythme intensif; action de monter des escaliers, une rampe ou une échelle; marche rapide à petits pas; course sur une surface plane; marche à une vitesse supérieure à 7 km/h sur une surface plane.'],
          ],

          columns: [
            { header: '', dataKey: 'id' }, // Définir une largeur minimale de 50 pour cette colonne
            { header: 'Métabolisme énergétique [W]', dataKey: 'type' },
            { header: 'Exemple', dataKey: 'ex' },
          ],

        });

    



        doc.addPage();
        doc.setFontSize(20); // Définir la taille du texte à 12 points
        doc.setFont('SourceCodePro', 'Medium');
        doc.text('Annexes B', 20, 25);
        
        autoTable(doc, {
          // margin: { top: 20, left:75, right: 10 }, // Spécifie le décalage par rapport au bord supérieur et gauche de la page
          startY: 40,
          theme: 'grid',

          headStyles: {
            fillColor: '#FCF8E3', // #DC3545 #FFA500 #28A745// Changer la couleur de fond de la ligne d'en-tête
            textColor: '#8A6D3A',
            font: 'SourceCodePro',
            fontStyle: 'Black',
          },
          bodyStyles:{
            font: 'SourceCodePro',
            fontStyle: 'Regular',
            // FontSize: 11,
          },
          columnStyles: {
            // 1: {cellWidth: 30},
            0: {cellWidth: 50},
            2: {halign: 'center'},
          },
          // rowStyles: {
          //   3: { fillColor: '#EB3434' }, // Appliquer la couleur de fond à la deuxième ligne (indexée à partir de 0) #F5F5F5
          // },
          head: [
            [
              // { content: 'Titre', colSpan: 2, styles: { halign: 'center', fillColor: [22, 160, 133] } },
              { content: 'Valeurs d\'ajustement de l\'indice WBGT pour les vêtements (CAV) pour différentes tenues vestimentaires en °C-WBGT', colSpan: 3, styles: { halign: 'center' }}
              // '',
            ],
          ],

          body: [
            [
            { content: 'Vêtement', styles: { fontStyle: 'Bold' }},
            { content: 'Commentaire', styles: { fontStyle: 'Bold' }},
            { content: 'CAV [°C-WBGT]', styles: { fontStyle: 'Bold' }},

            ],
            [
              { content: 'Vêtements de travail', styles: { fillColor: (CAV_key === 0 || CAV_key === 8) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 0 || CAV_key === 8) ? '#000000' : '#505050' } },
              { content: 'Des vêtements de travail en étoffe tissée sont la tenue de référence.', styles: { fillColor: (CAV_key === 0 || CAV_key === 8) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 0 || CAV_key === 8) ? '#000000' : '#505050' } },
              { content: '0', styles: { fillColor: (CAV_key === 0 || CAV_key === 8) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 0 || CAV_key === 8) ? '#000000' : '#505050', fontStyle: (CAV_key === 0 || CAV_key === 8) ? 'Bold' : 'Regular' } },
            ],
            [
              // { content: 'Combinaisons', styles: { fillColor: (CAV_key === 1 || CAV_key === 9) ? '#F5F5F5' : '#FFFFFF', textColor: (CAV_key === 1 || CAV_key === 9) ? '#000000' : '#505050', fontStyle: (CAV_key === 1 || CAV_key === 9) ? 'Bold' : 'Regular' } },
              { content: 'Combinaisons', styles: { fillColor: (CAV_key === 1 || CAV_key === 9) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 1 || CAV_key === 9) ? '#000000' : '#505050' } },
              { content: 'Étoffe tissée contenant du coton traité.', styles: { fillColor: (CAV_key === 1 || CAV_key === 9) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 1 || CAV_key === 9) ? '#000000' : '#505050' } },
              { content: '0', styles: { fillColor: (CAV_key === 1 || CAV_key === 9) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 1 || CAV_key === 9) ? '#000000' : '#505050', fontStyle: (CAV_key === 1 || CAV_key === 9) ? 'Bold' : 'Regular' } },
            ],
            [
              { content: 'Combinaisons en SMS non tissé en une seule couche', styles: { fillColor: (CAV_key === 2 || CAV_key === 10) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 2 || CAV_key === 10) ? '#000000' : '#505050' } },
              { content: 'Procédé non propriétaire pour fabriquer des étoffes non tissées à partir de polypropylène.', styles: { fillColor: (CAV_key === 2 || CAV_key === 10) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 2 || CAV_key === 10) ? '#000000' : '#505050' } },
              { content: '0', styles: { fillColor: (CAV_key === 2 || CAV_key === 10) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 2 || CAV_key === 10) ? '#000000' : '#505050', fontStyle: (CAV_key === 2 || CAV_key === 10) ? 'Bold' : 'Regular' } },
            ],
            [
              { content: 'Combinaisons en polyoléfine non tissée en une seule couche', styles: { fillColor: (CAV_key === 3 || CAV_key === 11) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 3 || CAV_key === 11) ? '#000000' : '#505050' } },
              { content: 'Étoffe propriétaire fabriquée à partir de polyéthylène.', styles: { fillColor: (CAV_key === 3 || CAV_key === 11) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 3 || CAV_key === 11) ? '#000000' : '#505050' } },
              { content: '2', styles: { fillColor: (CAV_key === 3 || CAV_key === 11) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 3 || CAV_key === 11) ? '#000000' : '#505050', fontStyle: (CAV_key === 3 || CAV_key === 11) ? 'Bold' : 'Regular' } },
            ],
            [
              { content: 'Tablier pare-vapeur à manches longues et combinaisons longues', styles: { fillColor: (CAV_key === 4 || CAV_key === 12) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 4 || CAV_key === 12) ? '#000000' : '#505050' } },
              { content: 'La configuration de tablier enveloppant a été conçue pour protéger la partie antérieure et les parties latérales du corps contre les déversements accidentels d\'agents chimiques.', styles: { fillColor: (CAV_key === 4 || CAV_key === 12) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 4 || CAV_key === 12) ? '#000000' : '#505050' } },
              { content: '4', styles: { fillColor: (CAV_key === 4 || CAV_key === 12) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 4 || CAV_key === 12) ? '#000000' : '#505050', fontStyle: (CAV_key === 4 || CAV_key === 12) ? 'Bold' : 'Regular' } },
            ],
            [
              { content: 'Double couche de vêtements tissés', styles: { fillColor: (CAV_key === 5 || CAV_key === 13) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 5 || CAV_key === 13) ? '#000000' : '#505050' } },
              { content: 'Généralement considérés comme des combinaisons au-dessus de vêtements de travail.', styles: { fillColor: (CAV_key === 5 || CAV_key === 13) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 5 || CAV_key === 13) ? '#000000' : '#505050' } },
              { content: '3', styles: { fillColor: (CAV_key === 5 || CAV_key === 13) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 5 || CAV_key === 13) ? '#000000' : '#505050', fontStyle: (CAV_key === 5 || CAV_key === 13) ? 'Bold' : 'Regular' } },
            ],
            [
              { content: 'Combinaisons pare-vapeur en une seule couche, sans cagoule', styles: { fillColor: CAV_key === 6 ? '#DEE2E6' : '#FFFFFF', textColor: CAV_key === 6 ? '#000000' : '#505050' } },
              { content: 'L\'effet réel dépend du niveau d\'humidité et, dans bien des cas, l\'effet est moindre.', styles: { fillColor: CAV_key === 6 ? '#DEE2E6' : '#FFFFFF', textColor: CAV_key === 6 ? '#000000' : '#505050' } },
              { content: '10', styles: { fillColor: CAV_key === 6 ? '#DEE2E6' : '#FFFFFF', textColor: CAV_key === 6 ? '#000000' : '#505050', fontStyle: CAV_key === 6 ? 'Bold' : 'Regular' } },
            ],
            [
              { content: 'Combinaisons pare-vapeur à cagoule en une seule couche', styles: { fillColor: CAV_key === 14 ? '#DEE2E6' : '#FFFFFF', textColor: CAV_key === 14 ? '#000000' : '#505050' } },
              { content: 'L\'effet réel dépend du niveau d\'humidité et, dans bien des cas, l\'effet est moindre.', styles: { fillColor: CAV_key === 14 ? '#DEE2E6' : '#FFFFFF', textColor: CAV_key === 14 ? '#000000' : '#505050' } },
              { content: '11', styles: { fillColor: CAV_key === 14 ? '#DEE2E6' : '#FFFFFF', textColor: CAV_key === 14 ? '#000000' : '#505050', fontStyle: CAV_key === 14 ? 'Bold' : 'Regular' } },
            ],
            [
              { content: 'Pare-vapeur au-dessus de combinaisons, sans cagoule', styles: { fillColor: CAV_key === 7 ? '#DEE2E6' : '#FFFFFF', textColor: CAV_key === 7 ? '#000000' : '#505050' } },
              { content: '-', styles: { fillColor: CAV_key === 7 ? '#DEE2E6' : '#FFFFFF', textColor: CAV_key === 7 ? '#000000' : '#505050' } },
              { content: '12', styles: { fillColor: CAV_key === 7 ? '#DEE2E6' : '#FFFFFF', textColor: CAV_key === 7 ? '#000000' : '#505050', fontStyle: CAV_key === 7 ? 'Bold' : 'Regular' } },
            ],
            [
              { content: 'Cagoule', styles: { fillColor: (CAV_key === 8 || CAV_key === 9 || CAV_key === 10 || CAV_key === 11 || CAV_key === 12 || CAV_key === 13) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 8 || CAV_key === 9 || CAV_key === 10 || CAV_key === 11 || CAV_key === 12 || CAV_key === 13) ? '#000000' : '#505050' } },
              { content: 'Port d\'une cagoule en étoffe avec n\'importe quelle tenue vestimentaire. Cette valeur est ajoutée à la valeur CAV de la tenue sans cagoule ni appareil respiratoire.', styles: { fillColor: (CAV_key === 8 || CAV_key === 9 || CAV_key === 10 || CAV_key === 11 || CAV_key === 12 || CAV_key === 13) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 8 || CAV_key === 9 || CAV_key === 10 || CAV_key === 11 || CAV_key === 12 || CAV_key === 13) ? '#000000' : '#505050' } },
              { content: '+1', styles: { fillColor: (CAV_key === 8 || CAV_key === 9 || CAV_key === 10 || CAV_key === 11 || CAV_key === 12 || CAV_key === 13) ? '#DEE2E6' : '#FFFFFF', textColor: (CAV_key === 8 || CAV_key === 9 || CAV_key === 10 || CAV_key === 11 || CAV_key === 12 || CAV_key === 13) ? '#000000' : '#505050', fontStyle: (CAV_key === 8 || CAV_key === 9 || CAV_key === 10 || CAV_key === 11 || CAV_key === 12 || CAV_key === 13) ? 'Bold' : 'Regular' } },
            ],
            [
              { content: 'NOTE: Pour les vêtements à forte résistance à l\'évaporation, il existe une dépendance par rapport à l\'humidité relative. Les valeurs CAV représentent la valeur élevée probable.', colSpan: 3, styles: { fontStyle: 'italic' }},
            ],
            
          ],

          columns: [
            { header: '', dataKey: 'id' }, // Définir une largeur minimale de 50 pour cette colonne
            { header: 'Métabolisme énergétique [W]', dataKey: 'type' },
            { header: 'Exemple', dataKey: 'ex' },
          ],

        });
      }



      
      




      doc.setFontSize(9); // Définir la taille du texte à 12 points

      // Ajouter des numéros de page
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        // doc.text("Page " + i + " of " + doc.getNumberOfPages(), 10, doc.internal.pageSize.height - 10);
        // doc.text("", 10, 257);
        // doc.text("", 10, 287);

        doc.setFont('MaterialIcons', 'Regular');
        doc.text("", 10, 287);
        doc.setFont('SourceCodePro', 'Regular');
        doc.text("info@truewindspeed.ch", 15, 287);

        // doc.setFont('MaterialIcons', 'Regular');
        // doc.text("", 70, 287);
        // doc.setFont('SourceCodePro', 'Regular');
        // doc.text("+41 79 280 89 09", 75, 287);

        doc.text("Page " + i + " sur " + doc.getNumberOfPages(), 170, 287);
      }



      // Sauvegarde du PDF avec le nom de fichier unique
      doc.save(nomFichier);
      // input.style.display = 'none'; // ou 'inline' ou toute autre valeur correspondant à votre style initial

      return doc;


  } catch (error) {
      console.error('Erreur lors de la génération du PDF :', error);
      throw error;
  }
};

// Fonction pour enregistrer le PDF en interne et envoyer une copie au script PHP
const savePDFInternally = async (doc, uniqueName) => {
  try {
      // Convertit le PDF en une chaîne de caractères
      // const pdfData = doc.output('blob');
      const pdfData = doc.output('blob');

      

      // Crée un objet FormData pour envoyer le PDF au script PHP
      const formData = new FormData();
      formData.append('pdf', pdfData, uniqueName);
      // formData.append('pdf', pdfData, 'document.pdf');

      // Envoie le PDF au script PHP
      const response = await fetch('./pdf/save_pdf.php', {
          method: 'POST',
          body: formData,
      });

      // Vérifie si la requête a réussi
      if (response.ok) {
          console.log('PDF enregistré avec succès en interne.');
      } else {
          console.error('Erreur lors de l\'enregistrement du PDF en interne.');
      }
  } catch (error) {
      console.error('Une erreur s\'est produite lors de l\'enregistrement du PDF en interne :', error);
  }
};











Chart.register(CategoryScale)
// ,  WBGT_received_at, plot_WBGT,  HighLim, LowLim,  received_atTempHum, plot_TempC_SHT,  ColdLim
function ExportButton({ data_WBGT_small, options_WBGT_small, data_Temp, options_Temp, DateTimeRange, pointSource, spot, M, M_key, M_types, CAV, CAV_key, CAV_types, WBGT_received_time, receivedtimeTempHum}) {
  
  const WBGT_received_at = data_WBGT_small.labels;
  const WBGT_data = data_WBGT_small.datasets[0].data;
  const HighLim = data_WBGT_small.datasets[1].data[0];
  const LowLim = data_WBGT_small.datasets[2].data[0];
  
  const received_atTempHum = data_Temp.labels;
  const Temp_data = data_Temp.datasets[0].data;
  const ColdLim = data_Temp.datasets[1].data[0];


  // console.log('WBGT_received_at');
  // console.log(WBGT_received_at);
  // console.log('WBGT_data');
  // console.log(WBGT_data);
  // console.log('HighLim');
  // console.log(HighLim);
  // console.log('LowLim');
  // console.log(LowLim);
  // console.log('ColdLim');
  // console.log(ColdLim);
  // console.log('received_atTempHum');
  // console.log(received_atTempHum);
  // console.log('Temp_data');
  // console.log(Temp_data);




// const WBGT_received_at = ['27/02 10:00', '27/02 10:10', '27/02 10:20', '27/02 10:30', '27/02 10:40', '27/02 10:50', '27/02 11:00'];
// const WBGT_data = [25, 29, 30, 31, 27, 30, 29, 24];
// const HighLim = 28;
// const LowLim = 26;

// const received_atTempHum = ['27/02 10:00', '27/02 10:10', '27/02 10:20', '27/02 10:30', '27/02 10:40', '27/02 10:50', '27/02 11:00'];
// const Temp_data = [6, 9, 0, 1, 7, 0, 9, 4];
// const ColdLim = 5
  

const { overHighLim, overLowLim, overColdLim } = overlimit(WBGT_received_at, WBGT_data, HighLim, LowLim, received_atTempHum, Temp_data, ColdLim, DateTimeRange);
// console.log('overHighLim:', overHighLim);
// console.log('overLowLim:', overLowLim);
// console.log('overColdLim:', overColdLim);





  const [showchart, setShowchart] = useState(false);
  

  let ref_WBGT = useRef(null);
  let ref_Temp = useRef(null);
  


  const downloadImage = useCallback(() => {
    const link = document. createElement ("a");
    link.download = "chart.png";
    link.href = ref_WBGT.current.toBase64Image();
    link.click();

  }, []);


    const stations = pointSource.data.features;
    let spotObject = stations.find((s) => s.properties.spot_id === spot);
    let spotName = spotObject ? spotObject.properties.spotname : "Spot non trouvé";
    let spotID = spotObject ? spotObject.properties.spot_id : "Spot non trouvé";
    let spotCoordinates = spotObject ? spotObject.geometry.coordinates: "Spot non trouvé";

    const [showExport, setshowExport] = useState(false); // état initial de 6 heures

    const exportToCSV = () => {
      // Convertir les données en un tableau au format CSV
      let csvContent = "data:text/csv;charset=utf-8,";

      // Ajouter les en-têtes
      csvContent += "Date,WBGT °C,Limite Acclimaté °C,Limite non-Acclimaté °C,Température °C\n";

      // Ajouter les lignes de données
      for (let i = 0; i < WBGT_received_time.length; i++) {
        csvContent += `${WBGT_received_time[i]},${WBGT_data[i]},${HighLim.toFixed(1)},${LowLim.toFixed(1)},${Temp_data[i]}\n`;
        // csvContent += `${WBGT_received_at[i]},${WBGT_data[i]},${HighLim},${LowLim},${received_atTempHum[i]},${Temp_data[i]},${ColdLim}\n`;
      }

      // Créer un objet URL pour le contenu CSV
      const encodedUri = encodeURI(csvContent);

      const { uniqueName, randomId, saveName } = uniqueFilename('data');

      // Créer un lien temporaire pour télécharger le fichier CSV
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${saveName}.csv`);
      document.body.appendChild(link);

      // Cliquer sur le lien pour déclencher le téléchargement
      link.click();

      // Supprimer le lien du corps du document une fois le téléchargement terminé
      document.body.removeChild(link);
    };

    

    const exportToExcel = () => {
      // Créer un objet WorkBook
      const wb = XLSX.utils.book_new();
    
      // Créer une feuille de calcul
      const ws = XLSX.utils.aoa_to_sheet([
        ["Date", "WBGT °C", "Limite Acclimaté °C", "Limite non-Acclimaté °C", "Température °C"], // En-têtes
        ...WBGT_received_time.map((time, index) => [
          time,
          WBGT_data[index],
          HighLim.toFixed(1),
          LowLim.toFixed(1),
          Temp_data[index]
        ]) // Données
      ]);
    
      // Ajouter la feuille de calcul au WorkBook
      XLSX.utils.book_append_sheet(wb, ws, "Data");
    
      // Générer un fichier Excel binaire
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    
      // Convertir le buffer en un objet Blob
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    
      // Créer un objet URL pour le blob
      const url = URL.createObjectURL(blob);

      const { uniqueName, randomId, saveName } = uniqueFilename('data');

    
      // Créer un lien temporaire pour télécharger le fichier Excel
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute("download", `${saveName}.xlsx`);
      document.body.appendChild(link);
    
      // Cliquer sur le lien pour déclencher le téléchargement
      link.click();
    
      // Supprimer le lien du corps du document une fois le téléchargement terminé
      document.body.removeChild(link);
    };




    
    const exportToPDF = () => {

        setshowExport(true);


        const Name = 'Certificat';
        const { uniqueName, randomId, saveName } = uniqueFilename(Name);
        // Générer le QR code
        generateQRCode(`https://sse.truewindspeed.ch/pdf/download/${uniqueName}`)
          .then((qrCodeDataURL) => {
            // Générer le PDF avec le QR code
            const temp=1;
            const wbgt=1;
            return generatePDF(temp, wbgt, qrCodeDataURL, ref_WBGT.current.toBase64Image(), ref_Temp.current.toBase64Image(), DateTimeRange, spotName, spotCoordinates, randomId, Name, overHighLim, overLowLim, overColdLim, M, M_key, M_types, CAV, CAV_key, CAV_types);
          })
          .then((doc) => {
              // Enregistrer le PDF en interne
              savePDFInternally(doc, uniqueName);
          })
          .catch((error) => {
              console.error('Une erreur s\'est produite :', error);
          });
        


          // const Name_WBGT = 'Certificat_WBGT';
          // const { uniqueName:uniqueName_WBGT, randomId:randomId_WBGT, saveName:saveName_WBGT } = uniqueFilename(Name_WBGT);
          // console.log('randomId_WBGT')
          // console.log(randomId_WBGT)
          // // Générer le QR code
          // generateQRCode(`https://sse.truewindspeed.ch/pdf/download/${uniqueName_WBGT}`)
          //   .then((qrCodeDataURL) => {
          //       // Générer le PDF avec le QR code
          //       const temp=0;
          //       const wbgt=1;
          //       return generatePDF(temp, wbgt, qrCodeDataURL, ref_WBGT.current.toBase64Image(), ref_Temp.current.toBase64Image(), DateTimeRange, spotName, spotCoordinates, randomId_WBGT, Name_WBGT, overHighLim, overLowLim, overColdLim, M, M_key, M_types, CAV, CAV_key, CAV_types);
          //   })
          //   .then((doc) => {
          //       // Enregistrer le PDF en interne
          //       savePDFInternally(doc, uniqueName_WBGT);
          //   })
          //   .catch((error) => {
          //       console.error('Une erreur s\'est produite :', error);
          //   });



          // const Name_Temp = 'Certificat_Cold';
          // const { uniqueName:uniqueName_Temp, randomId:randomId_Temp, saveName:saveName_Temp } = uniqueFilename(Name_Temp);
          // // Générer le QR code
          // generateQRCode(`https://sse.truewindspeed.ch/pdf/download/${uniqueName_Temp}`)
          //   .then((qrCodeDataURL) => {
          //       // Générer le PDF avec le QR code
          //       const temp=1;
          //       const wbgt=0;
          //       return generatePDF(temp, wbgt, qrCodeDataURL, ref_WBGT.current.toBase64Image(), ref_Temp.current.toBase64Image(), DateTimeRange, spotName, spotCoordinates, randomId_Temp, Name_Temp, overHighLim, overLowLim, overColdLim, M, M_key, M_types, CAV, CAV_key, CAV_types);
          //   })
          //   .then((doc) => {
          //       // Enregistrer le PDF en interne
          //       savePDFInternally(doc, uniqueName_Temp);
          //   })
          //   .catch((error) => {
          //       console.error('Une erreur s\'est produite :', error);
          //   });
    };

    const exportToPDF_WBGT = () => {

      setshowExport(true);
  
  
      const Name = 'Certificat_wbgt';
      const { uniqueName, randomId, saveName } = uniqueFilename(Name);
      // Générer le QR code
      generateQRCode(`https://sse.truewindspeed.ch/pdf/download/${uniqueName}`)
        .then((qrCodeDataURL) => {
          // Générer le PDF avec le QR code
          const temp=0;
          const wbgt=1;
          return generatePDF(temp, wbgt, qrCodeDataURL, ref_WBGT.current.toBase64Image(), ref_Temp.current.toBase64Image(), DateTimeRange, spotName, spotCoordinates, randomId, Name, overHighLim, overLowLim, overColdLim, M, M_key, M_types, CAV, CAV_key, CAV_types);
        })
        .then((doc) => {
            // Enregistrer le PDF en interne
            savePDFInternally(doc, uniqueName);
        })
        .catch((error) => {
            console.error('Une erreur s\'est produite :', error);
        });
    };
  
    const exportToPDF_Temp = () => {
  
      setshowExport(true);
  
  
      const Name = 'Certificat_cold';
      const { uniqueName, randomId, saveName } = uniqueFilename(Name);
      // Générer le QR code
      generateQRCode(`https://sse.truewindspeed.ch/pdf/download/${uniqueName}`)
        .then((qrCodeDataURL) => {
          // Générer le PDF avec le QR code
          const temp=1;
          const wbgt=0;
          return generatePDF(temp, wbgt, qrCodeDataURL, ref_WBGT.current.toBase64Image(), ref_Temp.current.toBase64Image(), DateTimeRange, spotName, spotCoordinates, randomId, Name, overHighLim, overLowLim, overColdLim, M, M_key, M_types, CAV, CAV_key, CAV_types);
        })
        .then((doc) => {
            // Enregistrer le PDF en interne
            savePDFInternally(doc, uniqueName);
        })
        .catch((error) => {
            console.error('Une erreur s\'est produite :', error);
        });
    };
  
  
  return (
    <>
  
        {/* <Col></Col>
        <Col>
            <br></br> */}
            <br></br>
                <DropdownButton id="dropdown-basic-button" variant="outline-secondary" title="Exporter">
                    <Dropdown.Item onClick={exportToCSV}>Export CSV <BsFiletypeCsv/></Dropdown.Item>
                    <Dropdown.Item onClick={exportToExcel}>Export Excel <BsFileEarmarkExcel/></Dropdown.Item>
                    <Dropdown.Item onClick={exportToPDF}>Export PDF <BsFiletypePdf/><MdOutlineSecurity/></Dropdown.Item>
                    <Dropdown.Item onClick={exportToPDF_WBGT}>Export PDF - Chaleur <BsFiletypePdf/><MdOutlineSecurity/></Dropdown.Item>
                    <Dropdown.Item onClick={exportToPDF_Temp}>Export PDF - Froid <BsFiletypePdf/><MdOutlineSecurity/></Dropdown.Item>
                </DropdownButton>
        {/* </Col>
        <Col></Col>
     */}
  
    {/* <DropdownButton id="dropdown-basic-button" variant="outline-secondary" title="Exporter">
      <Dropdown.Item onClick={exportToCSV}>Export CSV</Dropdown.Item>
      <Dropdown.Item onClick={exportToPDF}>Export PDF</Dropdown.Item>
    </DropdownButton> */}
  
  
  
    {/* {showExport==true && */}
    {/* <div style={{height:"60vh"}} >
        <Line data={data} options={options} id='chart'/>
    </div> */}
    {/* } */}
  
  
    {/* <div style={{height:"100px", width:"100px"}} >
      <Line ref={ref} data={data} options={options} id="chart" style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}/>
    </div> */}
  
  
  
  
        {/* <div> */}
          {/* <Line ref={ref_WBGT} data={data_bar} options={options_bar} style={{ width:'21cm', height:'10cm', position: 'absolute', left: '-9999px', top: '-9999px' }}/> */}
          
          {/* <Line ref={ref_WBGT} data={data_WBGT_small} options={options_WBGT_small} style={{ width:'200px', height:'100px', position: 'absolute', left: '-9999px', top: '-9999px' }}/>
          <Line ref={ref_Temp} data={data_Temp} options={options_Temp} style={{ width:'200px', height:'60px', position: 'absolute', left: '-9999px', top: '-9999px' }}/> */}
          
  
          <div style={{ width:'19cm', height:'9cm', position: 'absolute', left: '-9999px', top: '-9999px' }}>
            <Line ref={ref_WBGT} data={data_WBGT_small} options={options_WBGT_small} />
          </div>
  
          <div style={{ width:'19cm', height:'9cm', position: 'absolute', left: '-9999px', top: '-9999px' }}>
          <Line ref={ref_Temp} data={data_Temp} options={options_Temp} />
          </div>
  
          {/* <Line ref={ref_WBGT} data={data_WBGT_small} options={options_WBGT_small} style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}/>
          <Line ref={ref_Temp} data={data_Temp} options={options_Temp} style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}/> */}
  
  
          {/* <Line ref={ref} data={data_bar} options={options} style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}/> */}
        {/* </div> */}
  
   
  
    </>
  );
  }
  
  export default ExportButton;
  
  
  
  
  