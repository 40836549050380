import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import Carousel from 'react-bootstrap/Carousel';


// import ycg_Image from '../image/YCG.jpg';

// import sng from '../image/sng.svg';
// //import SNG_LOGO from '../image/SNG_LOGO_RVB.jpg';
// import CNV from '../image/CNV.svg';
// import ski_joux from '../image/ski-nautique-wakeboard-lac-de-joux.svg';
// import cheneBougeries from '../image/Chene-Bougeries.png';

import logo from '../image/logo.jpg';
import SNG_logo from '../image/SNG_logo.jpg';
import BiseNoire_logo from '../image/BiseNoire_logo.jpg';
import silva_logo from '../image/silvaplana-logo.png';
import trop_logo from '../image/trop.png';



// Importez les autres images nécessaires ici...

// Créez un objet pour mapper les valeurs de spoticon avec les chemins d'accès des images correspondantes
const spotIconMap = {
  'logo': logo,
  'CHGE03': SNG_logo,
  '1-meteowind-iot-pro': logo,
  // 'silva': silva_logo
  // '1-meteowind-iot-pro': BiseNoire_logo
  // Ajoutez les autres spots et leurs chemins d'accès ici...
  // 'silva': trop_logo,
};

function Station(props) {

  const stations = props.pointSource.data.features;
  let spot = props.spot;
  let spotObject = stations.find((s) => s.properties.spot_id === spot);

  // Récupérer le nom du spot s'il est trouvé
  let Cam1 = spotObject ? spotObject.properties.webcamlink : '';
  let Cam2 = spotObject ? spotObject.properties.webcamlink2 : '';
  let Cam3 = spotObject ? spotObject.properties.webcamlink3 : '';
  let Cam4 = spotObject ? spotObject.properties.webcamlink4 : '';

  // let Cam1 = ''//'https://camstreamer.com/embed/lVNnl7WD9PUbCYZmVBHSHgLSS79a2ysf9JXtICAA';
  // let Cam2 = 'https://camstreamer.com/embed/zVhwkln0zC279WEHYTTlDaxQXP76eXWxCdNOfcmc';
  // let Cam3 = 'https://camstreamer.com/embed/5QSFHqNMXdfZPRsP6R4LRYG8PlFvzgOVxFoAMRfT';
  // let Cam4 = ''//'https://camstreamer.com/embed/oyMdMcmrYnc7jbdRoGGqhxIfykin6zuz8uPFuhye';
  const camArray = [Cam1, Cam2, Cam3, Cam4];
  // const filteredCams = camArray.filter(cam => cam.length > 10);
  const filteredCams = camArray.filter(cam => typeof cam === 'string' && cam.length > 10);
  // Cam = 'null';
  // let Cam = "https://www.youtube.com/embed/QP4CdQ0J2Uk"
  let spotName = spotObject ? spotObject.properties.spotname : "Spot non trouvé";
  let spotID = spotObject ? spotObject.properties.spot_id : "Spot non trouvé";
  // console.log('spotID'); // Vérifiez la valeur de spoticon dans la console
  // console.log(spotID); // Vérifiez la valeur de spoticon dans la console
  // console.log('spot');
  // console.log(spot);
  // console.log('spotObject.properties.spot_id');
  // console.log(spotObject.properties.spot_id);
  // console.log('spotObject.properties.webcamlink');
  // console.log(spotObject.properties.webcamlink);
  // console.log('filteredCams');
  // console.log(filteredCams);
  // console.log('filteredCams.length');
  // console.log(filteredCams.length);
  // Utilisez l'objet spotIconMap pour obtenir le chemin d'accès de l'image correspondante
  let imageSource = spotIconMap[spotID] || logo; // Utilisez logo comme valeur par défaut
  // console.log(imageSource); // Vérifiez la valeur de imageSource dans la console


  let spotlong = spotObject ? spotObject.geometry.coordinates[0] : 0;
  let spotlat = spotObject ? spotObject.geometry.coordinates[1] : 0;

  props.selectstation(spotlong, spotlat);
  // console.log('Cam');
  // console.log(Cam1);



  const generateIframe = (url) => (
    <iframe
     width="100%" height="315" 
     src={url}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
          style={{
            // border: "1px solid #CED4DA", //#0D6EFD",
            borderRadius: "10px", // Bordure avec un arrondi aux coins
            overflow: "hidden", // Empêche le contenu de déborder de la bordure
            margin: "0", // Supprime les marges par défaut
            padding: "0" // Supprime le padding par défaut
          }}></iframe>
  );

  const renderSingleVideo = (cam) => {
    if (filteredCams.length != 0) {
      if (cam.length > 10) {
        return (
          <>
          <br/>
            <Row>
                {generateIframe(cam)}
            </Row>
          </>
        );
      }
    }
    return null;
  };

  const renderCarousel = () => {
    // const camArray = [Cam1, Cam2, Cam3, Cam4];
    // const filteredCams = camArray.filter(cam => cam.length > 10);

    if (filteredCams.length > 1) {
      return (
        <>
        <br/>
        <Row>
          <Carousel
          fade={true}
          touch={true}
          autoPlay={true}
          interval={5000}
          controls={false}
          indicators={true}

          width="560" height="315" 
          style={{
            // border: "1px solid #CED4DA", //#0D6EFD",
            borderRadius: "10px", // Bordure avec un arrondi aux coins
            overflow: "hidden", // Empêche le contenu de déborder de la bordure
            margin: "0", // Supprime les marges par défaut
            padding: "0" // Supprime le padding par défaut
          }}
          >
            {filteredCams.map((cam, index) => (
              <Carousel.Item key={index}>
                {generateIframe(cam)}
                {/* <Carousel.Caption>
                  {generateIframe(cam)}
                  {/* <h3>Slide label</h3>
                  <p>Description here</p> *}
                </Carousel.Caption> */}
              </Carousel.Item>
            ))}
           
          </Carousel>
        </Row>
        </>
      );
    }
    return null;
  };

  return (
    <Container fluid>
            {filteredCams.length > 1 ? renderCarousel() : 
            renderSingleVideo(filteredCams[0])
            }

      {/* {renderSingleVideo(Cam1) || renderSingleVideo(Cam2) || renderSingleVideo(Cam3) || renderSingleVideo(Cam4)}
      {renderCarousel()} */}


{/*       
      
      {/* {Cam !== 'Pas de lien' && ( */}
      {/* {0 !== 1 && ( */}
      {/* {Cam !== 'null' && ( */}
      {/* {Cam !== 'null' && Cam.length > 10 && ( *}
      {Cam.length > 10 && (

        <>
        <br/>
        <Row>
          <iframe width="560" height="315" src={Cam}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
          style={{
            // border: "1px solid #CED4DA", //#0D6EFD",
            borderRadius: "10px", // Bordure avec un arrondi aux coins
            overflow: "hidden", // Empêche le contenu de déborder de la bordure
            margin: "0", // Supprime les marges par défaut
            padding: "0" // Supprime le padding par défaut
          }}></iframe>
        </Row>
        {/* <br/> *}
        </>
      )}



      {Cam.length > 10 && (
      <>
      <br/>
      <Row>
      <iframe width="560" height="315" src={Cam}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
          style={{
            // border: "1px solid #CED4DA", //#0D6EFD",
            borderRadius: "10px", // Bordure avec un arrondi aux coins
            overflow: "hidden", // Empêche le contenu de déborder de la bordure
            margin: "0", // Supprime les marges par défaut
            padding: "0" // Supprime le padding par défaut
          }}></iframe>



        <Carousel
        width="560" height="315" 
        style={{
          // border: "1px solid #CED4DA", //#0D6EFD",
          borderRadius: "10px", // Bordure avec un arrondi aux coins
          overflow: "hidden", // Empêche le contenu de déborder de la bordure
          margin: "0", // Supprime les marges par défaut
          padding: "0" // Supprime le padding par défaut
        }}
        >
          {Cam.length > 10 && (
          <Carousel.Item>
            <Row>
              <iframe width="560" height="315" src={Cam}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
            style={{
              // border: "1px solid #CED4DA", //#0D6EFD",
              borderRadius: "10px", // Bordure avec un arrondi aux coins
              overflow: "hidden", // Empêche le contenu de déborder de la bordure
              margin: "0", // Supprime les marges par défaut
              padding: "0" // Supprime le padding par défaut
            }}></iframe>
            </Row>
            <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
          </Carousel.Item>
          )}
          {Cam2.length > 10 && (
          <Carousel.Item>
            <Row>
              <iframe width="560" height="315" src={Cam2}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
            style={{
              // border: "1px solid #CED4DA", //#0D6EFD",
              borderRadius: "10px", // Bordure avec un arrondi aux coins
              overflow: "hidden", // Empêche le contenu de déborder de la bordure
              margin: "0", // Supprime les marges par défaut
              padding: "0" // Supprime le padding par défaut
            }}></iframe>
            </Row>
            <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
          </Carousel.Item>
          )}
          {Cam3.length > 10 && (
          <Carousel.Item>
            <Row>
              <iframe width="560" height="315" src={Cam3}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
            style={{
              // border: "1px solid #CED4DA", //#0D6EFD",
              borderRadius: "10px", // Bordure avec un arrondi aux coins
              overflow: "hidden", // Empêche le contenu de déborder de la bordure
              margin: "0", // Supprime les marges par défaut
              padding: "0" // Supprime le padding par défaut
            }}></iframe>
            </Row>
            <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
          </Carousel.Item>
          )}
          {Cam4.length > 10 && (
          <Carousel.Item>
            <Row>
              <iframe width="560" height="315" src={Cam4}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
            style={{
              // border: "1px solid #CED4DA", //#0D6EFD",
              borderRadius: "10px", // Bordure avec un arrondi aux coins
              overflow: "hidden", // Empêche le contenu de déborder de la bordure
              margin: "0", // Supprime les marges par défaut
              padding: "0" // Supprime le padding par défaut
            }}></iframe>
            </Row>
            <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
          </Carousel.Item>
          )}
        </Carousel>
        </Row>
      </>
      )} */}



      <Row>
        <br/>
      </Row>


      <Row>
          <h5>
            {spotName} <img src={imageSource} style={{ maxHeight: "5vh", maxWidth: "15vh", margin: "0 auto", textAlign: "right" }} />
          </h5>
          <p style={{ fontSize: "1.5vh", color: "gray", textAlign: 'right', fontWeight:"150" }}>{props.timeAgo}</p>
      </Row>

    </Container>
  );
}



export default Station;

//                <Col sm={8} lg={8} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>



