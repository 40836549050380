import React from 'react';

import { BsFillTelephoneFill, BsEnvelopeAt } from "react-icons/bs";


const Contact = () => {
    const numeroTelephone = '+41 79 280 89 09'; // Remplacez par votre numéro de téléphone
    const adresseEmail = 'guillaume@locher-ge.ch'; // Remplacez par votre adresse e-mail


  return (
    // <div style={{ fontSize: "0.4em", color: "gray", textAlign: 'right', fontWeight:"150" }}>
    <div style={{ color: "gray", textAlign:'center' }}>
        <br></br>
        <br></br>
        {/* <p><BsFillTelephoneFill /> Téléphone : +41 79 280 89 09 </p>
        <p><BsEnvelopeAt /> e-mail : guillaume@locher-ge.ch </p> */}
        
        <a href={`tel:${numeroTelephone}`} style={{ color: "gray", textDecoration: 'none', }}><BsFillTelephoneFill /> Téléphone : {numeroTelephone}</a>
        <br></br>
        <a href={`mailto:${adresseEmail}`} style={{ color: "gray", textDecoration: 'none', }}><BsEnvelopeAt /> Mail : {adresseEmail}</a>
    </div>
  );
};

export default Contact;
