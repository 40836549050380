import { useState } from 'react';
// import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

import Table from 'react-bootstrap/Table'
import Accordion from 'react-bootstrap/Accordion';
// import Button from 'react-bootstrap/Button';

import { BsInfoCircle } from "react-icons/bs";
import { CenterFocusStrong } from '@mui/icons-material';


function Info() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* <Button variant="outline-info" onClick={handleShow} > <BsInfoCircle size={28}/> </Button> */}
      <BsInfoCircle size={28} color='#0D6EFD' onClick={handleShow}/>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Légendes</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>



            {/* <Table striped bordered> */}
            <Table bordered>
                <thead>
                    <tr>
                    {/* <th>Couleur</th> */}
                    <th>Variable</th>
                    <th colSpan={2}>Description</th>
                    </tr>
                </thead>
                <tbody>

                    <tr>
                    <td style={{ color: 'rgba(154, 102, 255)', textAlign: 'center' }}><strong>T<sub>nw</sub></strong></td>
                    <td colSpan={2}>Température humide naturelle °C</td>
                    </tr>
                    <tr>
                    <td style={{ color: 'rgba(5, 5, 5, 0.5)', textAlign: 'center' }}><strong>T<sub>g</sub></strong></td>
                    <td colSpan={2}>Température de globe noir °C</td>
                    </tr>
                    <tr>
                    <td style={{ color: 'rgb(255, 99, 132)', textAlign: 'center' }}><strong>T<sub>a</sub></strong></td>
                    <td colSpan={2}>Température de l'air °C</td>
                    </tr>

                    
                    <tr>
                    <td style={{ color: 'rgba(0, 0, 255, 1)', textAlign: 'center', verticalAlign: 'middle' }}><strong>WBGT</strong></td>
                    <td colSpan={2}>
                        Wet Bulb Globe Temperature °C <br/>
                        <i><span style={{ color: 'rgba(0, 0, 255, 1)' }}>WBGT</span> = <span style={{ color: 'rgba(154, 102, 255)' }}>0.7 T<sub>nw</sub></span> + <span style={{ color: 'rgba(5, 5, 5, 0.5)' }}>0.2 T<sub>g</sub></span> + <span style={{ color: 'rgb(255, 99, 132)' }}>0.1 T<sub>a</sub></span></i>
                    </td>
                    </tr>


                    <tr>
                    <td style={{ color: 'rgba(53, 162, 235)', textAlign: 'center', verticalAlign: 'middle' }}><strong>HR</strong></td>
                    <td colSpan={2}>humidité relative %<br/> <i>HR = <sup> P<sub>vap</sub> </sup>/<sub> P<sub>sat</sub>(T) </sub>&sdot; 100</i></td> 
                    {/* &phi; */}
                    </tr>


                    
                    <tr>
                    <td style={{ backgroundColor: 'rgba(255, 0, 0, 0.5)' }}> </td>
                    <td>Limite personnes aclimatées</td>
                    <td><i>WBGT<sub>ref</sub> &ge; 56.7 - 11.5 log<sub>10</sub>(M)</i></td>{/* &plusmn; */}
                    </tr>

                    <tr>
                    <td style={{ backgroundColor: 'rgba(255, 125, 0, 0.55)' }}> </td>
                    <td>Limite personnes non aclimatées</td>
                    <td><i>WBGT<sub>ref</sub> &ge; 59.9 - 14.1 log<sub>10</sub>(M)</i></td>
                    </tr>

                    <tr>
                    <td style={{ backgroundColor: 'rgba(0, 255, 0, 0.5)' }}> </td>
                    <td style={{verticalAlign: 'middle' }}>Zone de sécurité</td>
                    <td><i>WBGT<sub>ref</sub> &lt; 59.9 - 14.1 log<sub>10</sub>(M)</i></td>
                    </tr>

                    <tr>
                    <td style={{ backgroundColor: 'rgba(125, 255, 255, 0.5)' }}> </td>
                    <td style={{verticalAlign: 'middle' }}>Zone froide <br/> <p style={{ fontSize: 9 }}>attention à la mise en oeuvre de certains matériaux</p></td>
                    <td><i>T<sub>air</sub> &le; 5 °C</i></td>
                    </tr>


                    <tr>
                    <td style={{textAlign: 'center', verticalAlign: 'middle' }}>M</td>
                    <td colSpan={2}>Estimation du métabolisme énergétique W</td>
                    </tr>

                    <tr>
                    <td style={{textAlign: 'center', verticalAlign: 'middle' }}>CAV</td>
                    <td colSpan={2}>Valeur d'ajustement pour les vêtements °C-WBGT</td>
                    </tr>

                </tbody>
            </Table>

            
        {/* <Table striped bordered>
            <thead>
                <tr>
                    <th>
                        Classification des niveaux de métabolisme énergétique selon l'ISO 8996
                    </th>
                </tr>
                <tr>
                    <th>
                        
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        
                    </td>
                </tr>
            </tbody>
        </Table> */}


            <br></br>
            <br></br>
            <br></br>
            <br></br>



            <strong>Classification des niveaux de métabolisme énergétique selon l'ISO 8996</strong>
            {/* <Accordion defaultActiveKey="0"> */}
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                    <strong>#0&nbsp;&nbsp;&nbsp;</strong>Repos<br/> &rarr; 115W  (100 à 125)
                    </Accordion.Header>
                    <Accordion.Body>
                    Repos, position assise confortable
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                    <strong>#1&nbsp;&nbsp;&nbsp;</strong>Métabolisme énergétique faible<br/> &rarr; 180W (125W à 235W) 
                    </Accordion.Header>
                    <Accordion.Body>
                    Travail manuel léger (écriture, frappe à la machine, dessin, couture, comptabilité); travail des mains et des bras (petits outils d'établi, inspection, assemblage ou triage de matériaux légers); travail des bras et des jambes (conduite de véhicule dans des conditions normales, manœuvre d'un interrupteur à pied ou d'une pédale).
                    <br/><br/>
                    Debout: perceuse (petites pièces); fraiseuse (petites pieces); bobinage;
                    enroulement de petites armatures; usinage avec outlis de faible puissance;
                    marche occasionnelle sur une surface plane (vitesse jusqu'à 2,5 km/h).
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                    <strong>#2&nbsp;&nbsp;&nbsp;</strong>Métabolisme énergétique modéré<br/> &rarr; 300W (235W à 360W)
                    </Accordion.Header>
                    <Accordion.Body>
                    Travail soutenu des mains et des bras (cloutage, limage); travail des bras et des jambes (manœuvre sur chantiers de camions, tracteurs ou engins); travall des bras et du tronc (travail au marteau pneumatique, accouplement de véhicules, plâtrage, manipulation intermittente de matériaux modérément lourds, sarclage, binage, cueillette de fruits ou de légumes; poussée ou traction de charrettes légères ou de brouettes; marche à une vitesse de 2,5 à 5,5 km/h sur une surface plane; forgeage).
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                    <strong>#3&nbsp;&nbsp;&nbsp;</strong>Métabolisme énergétique élevé<br/> &rarr; 415W (360W à 465W)
                    </Accordion.Header>
                    <Accordion.Body>
                    Travail intense des bras et du tronc; transport de matériaux lourds; pelletage; travail au marteau; sciage, planage ou ciselage de bois dur:
fauchage manuel; excavation; marche à une vitesse de 5,5 à 7 km/h sur une surface plane.<br/><br/>
Poussée ou traction de charrettes à bras ou de brouettes lourdement chargées; enlèvement de copeaux de pièces moulées; pose de blocs de béton.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                    <strong>#4&nbsp;&nbsp;&nbsp;</strong>Métabolisme énergétique très élevé<br/> &rarr; 520W (&gt;465W)
                    </Accordion.Header>
                    <Accordion.Body>
                    Activité très intense à allure rapide proche du maximum; travail à la hache; pelletage ou excavation à un rythme intensif; action de monter des escaliers, une rampe ou une échelle; marche rapide à petits pas; course sur une surface plane; marche à une vitesse supérieure à 7 km/h sur une surface plane.
                    </Accordion.Body>
                </Accordion.Item>
                
            </Accordion>



            <br></br>
            <br></br>
            <br></br>



            <strong>Valeurs d'ajustement de l'indice WBGT pour les vêtements (CAV) pour différentes tenues vestimentaires en °C-WBGT</strong>
            {/* <Accordion defaultActiveKey="0"> */}
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                    <strong>0&nbsp;&nbsp;&nbsp;</strong>Vêtements de travail
                    </Accordion.Header>
                    <Accordion.Body>
                    Des vêtements de travail en étoffe tissée sont la tenue de référence.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                    <strong>0&nbsp;&nbsp;&nbsp;</strong>Combinaisons
                    </Accordion.Header>
                    <Accordion.Body>
                    Étoffe tissée contenant du coton traité.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                    <strong>0&nbsp;&nbsp;&nbsp;</strong>Combinaisons en SMS non tissé en une seule couche
                    </Accordion.Header>
                    <Accordion.Body>
                    Procédé non propriétaire pour fabriquer des étoffes non tissées à partir de polypropylène.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                    <strong>2&nbsp;&nbsp;&nbsp;</strong>Combinaisons en polyoléfine non tissée en une seule couche
                    </Accordion.Header>
                    <Accordion.Body>
                    Étoffe propriétaire fabriquée à partir de polyéthylène.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                    <strong>4&nbsp;&nbsp;&nbsp;</strong>Tablier pare-vapeur à manches longues et combinaisons longues
                    </Accordion.Header>
                    <Accordion.Body>
                    La configuration de tablier enveloppant a été conçue pour protéger la partie antérieure et les parties latérales du corps contre les déversements accidentels d'agents chimiques.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>
                    <strong>3&nbsp;&nbsp;&nbsp;</strong>Double couche de vêtements tissés
                    </Accordion.Header>
                    <Accordion.Body>
                    Généralement considérés comme des combinaisons au-dessus de vêtements de travail.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>
                    <strong>10&nbsp;&nbsp;&nbsp;</strong>Combinaisons pare-vapeur en une seule couche, sans cagoule
                    </Accordion.Header>
                    <Accordion.Body>
                    L'effet réel dépend du niveau d'humidité et, dans bien des cas, l'effet est moindre.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>
                    <strong>11&nbsp;&nbsp;&nbsp;</strong>Combinaisons pare-vapeur à cagoule en une seule couche
                    </Accordion.Header>
                    <Accordion.Body>
                    L'effet réel dépend du niveau d'humidité et, dans bien des cas, l'effet est moindre.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>
                    <strong>12&nbsp;&nbsp;&nbsp;</strong>Pare-vapeur au-dessus de combinaisons, sans cagoule
                    </Accordion.Header>
                    <Accordion.Body>
                        -
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header>
                    <strong>+1&nbsp;&nbsp;&nbsp;</strong>Cagoule
                    </Accordion.Header>
                    <Accordion.Body>
                    Port d'une cagoule en étoffe avec n'importe quelle tenue vestimentaire.
                    <br/>
                    <br/>
                    <i>Cette valeur est ajoutée à la valeur CAV de la tenue sans cagoule ni appareil respiratoire.</i>
                    </Accordion.Body>
                </Accordion.Item>
                <i>NOTE&nbsp; Pour les vêtements à forte résistance à l'évaporation, Il existe une dépendance par rapport à l'humidité relative. Les valeurs CAV représentent la valeur élevée probable.</i>
                
            </Accordion>




            <br></br>
            <br></br>
            <br></br>
            <br></br>


        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Info;